import { useRootLoaderData } from '@/hooks/useRootLoaderData';
import clsx from 'clsx';
import { useState } from 'react';
import { MarketingSiteLink } from '../MarketingSiteLink';
import { UserNavigation } from '../UserNavigation';
import { Button } from '../Button';
import { useChatTriggerCallback } from '@/hooks/useChatTriggerCallback';

function NavBar(props: { isActive: boolean }) {
  const { isActive } = props;
  return (
    <span
      className={clsx(
        'block h-[5px] w-12 rounded-xl bg-current transition-all duration-300 group-hover:bg-app-accent',
        {
          'my-2': !isActive,
          'absolute right-0 top-5 z-[51] m-0 rotate-45 last-of-type:-rotate-45':
            isActive,
        }
      )}
      aria-hidden="true"
    />
  );
}

interface Params {
  isPartner?: boolean;
}

export function TopNav(params: Params) {
  const { currentUser } = useRootLoaderData();
  const { isPartner } = params;
  const [navExpanded, setNavExpanded] = useState(false);

  const openChat = useChatTriggerCallback();

  const onToggleClick = () => {
    setNavExpanded((prev) => !prev);
  };

  if (!currentUser && isPartner) {
    return (
      <Button
        to="/login"
        intent="secondary"
        size="sm"
        onClick={() => onToggleClick()}
      >
        Login
      </Button>
    );
  }

  return (
    <>
      <button
        type="button"
        aria-haspopup="true"
        aria-controls="main-nav"
        aria-label="Toggle Navigation"
        aria-expanded={navExpanded}
        className="group relative -right-4 top-0 z-[51] m-4 block size-12 p-0 text-app-primary xl:hidden"
        onClick={onToggleClick}
      >
        <NavBar isActive={navExpanded} />
        <NavBar isActive={navExpanded} />
        <NavBar isActive={navExpanded} />
      </button>
      <nav
        aria-label="Primary Navigation"
        id="main-nav"
        className={clsx(
          'fixed inset-0 z-50 overflow-auto bg-app-secondary pt-20 font-heading xl:static xl:flex xl:items-center xl:overflow-visible xl:bg-transparent xl:p-0',
          {
            hidden: !navExpanded,
          }
        )}
      >
        {!isPartner && (
          <>
            <ul className="flex flex-col justify-center gap-10 text-center font-heading xl:flex-row xl:text-right">              
              <li>
                <Button
                  type="button"
                  intent="primary"
                  outline
                  size="sm"
                  onClick={openChat}
                >
                  Speak to an expert
                </Button>
              </li>

              <li className="flex flex-wrap justify-center gap-2 xl:hidden">
                <MarketingSiteLink
                  href="/online-scheduler"
                  className="inline-flex items-center gap-2 rounded-full bg-app-accent px-4 py-2 text-center text-sm font-bold uppercase text-app-accent-foreground"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 448 512"
                    aria-hidden="true"
                    fill="currentColor"
                  >
                    <path d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zm80 64c-8.8 0-16 7.2-16 16v64c0 8.8 7.2 16 16 16H368c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H80z" />
                  </svg>
                  Online Scheduler
                </MarketingSiteLink>
                <MarketingSiteLink
                  href="/client-referral"
                  className="inline-flex items-center gap-2 rounded-full bg-app-primary px-4 py-2 text-center text-sm font-bold uppercase text-app-primary-foreground"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 576 512"
                    aria-hidden="true"
                    fill="currentColor"
                  >
                    <path d="M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.6 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z" />
                  </svg>
                  Refer a Client
                </MarketingSiteLink>
              </li>
            </ul>
          </>
        )}

        <UserNavigation closeNav={() => setNavExpanded(false)} />
      </nav>
    </>
  );
}
