import { Link, isRouteErrorResponse, useRouteError } from 'react-router-dom';

import { isAxiosError } from 'axios';
import { PageLogo } from './components/layout/PageLogo';
import { useReportError } from './hooks/useReportError';
import { ThemeStyles } from './routes/ThemeStyles';

function GenericError({ message }: { message?: string }) {
  const showMessage = ['local', 'development'].includes(
    import.meta.env.VITE_ROLLBAR_ENVIRONMENT
  );
  return (
    <>
      <h1 className="mb-10 font-heading text-5xl font-bold text-app-primary">
        Oops!
      </h1>

      <p className="mb-10">
        It looks like we’ve hit a problem. No worries though. Your data is safe.
        We recommend going back and try submitting again.
      </p>
      <p className="mb-10">
        <button
          type="button"
          className="text-app-primary underline hover:no-underline"
          onClick={() => window.history.go(-1)}
        >
          Try going back
        </button>
      </p>
      {showMessage && !!message && (
        <code className="block rounded-xl bg-red-700 p-4 text-lg">
          {message}
        </code>
      )}
    </>
  );
}

function NotFoundError() {
  return (
    <>
      <h1 className="mb-10 font-heading text-5xl font-bold text-app-primary">
        Not Found
      </h1>

      <p className="text-center leading-relaxed">
        We cannot find the page you are looking for. Check the URL,{' '}
        <button
          type="button"
          className="text-app-primary underline hover:no-underline"
          onClick={() => window.history.go(-1)}
        >
          try going back
        </button>
        , or visit our{' '}
        <Link to="/" className="text-app-primary underline hover:no-underline">
          home page
        </Link>
        .
      </p>
    </>
  );
}

function UnauthorizedError() {
  return (
    <>
      <h1 className="mb-10 font-heading text-5xl font-bold text-app-primary">
        Unauthorized
      </h1>

      <p className="text-center leading-relaxed">
        You do not have the required permissions to view this page.{' '}
        <button
          type="button"
          className="text-app-primary underline hover:no-underline"
          onClick={() => window.history.go(-1)}
        >
          Try going back
        </button>
        , or{' '}
        <Link to="/" className="text-app-primary underline hover:no-underline">
          return to the home page
        </Link>
        .
      </p>
    </>
  );
}

function ErrorComponent({ error }: { error: unknown }) {
  const reportError = useReportError();

  if (isRouteErrorResponse(error) && error.status === 404) {
    return <NotFoundError />;
  }
  if (isAxiosError(error) && error.response?.status === 404) {
    return <NotFoundError />;
  }
  if (
    (isAxiosError(error) && error.response?.status === 403) ||
    (isRouteErrorResponse(error) && error.status === 403)
  ) {
    return <UnauthorizedError />;
  }

  reportError(error);

  if (error instanceof Error) {
    return <GenericError message={error.message} />;
  }
  console.error(error);
  return <GenericError />;
}

export default function ErrorPage() {
  const error = useRouteError();

  return (
    <>
      <ThemeStyles />
      <div className="fixed inset-0 z-50 bg-app-body font-sans text-xl  lg:flex lg:h-screen lg:items-center lg:justify-center lg:text-2xl">
        <div className="mx-auto my-4 max-w-xl text-center lg:m-auto lg:my-10 lg:p-10">
          <div className="mb-10">
            <Link to="/">
              <PageLogo className="mx-auto object-center lg:h-[60px] lg:w-[255px]" />
            </Link>
          </div>

          <ErrorComponent error={error} />
        </div>
      </div>
    </>
  );
}
