import { cva } from 'class-variance-authority';
import clsx from 'clsx';
import {
  ComponentType,
  MouseEventHandler,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import { InlineSpinner } from './InlineSpinner';

const buttonClasses = cva(
  [
    `relative inline-flex items-center justify-center gap-2 rounded-full
    border-2 font-heading
    font-bold uppercase transition-all
    hover:brightness-90 disabled:opacity-50`,
  ],
  {
    variants: {
      size: {
        sm: ['px-4 py-2 text-xs tracking-wider'],
        md: ['px-6 py-3 text-sm'],
        lg: ['px-10 py-5 text-base'],
      },
      intent: {
        primary: [
          'border-app-primary bg-app-primary text-app-primary-foreground',
        ],
        secondary: [
          'border-app-secondary bg-app-secondary text-app-secondary-foreground',
        ],
        tertiary: [
          'border-app-accent bg-app-accent text-app-accent-foreground',
        ],
        danger: ['border-app-red bg-app-red text-white hover:bg-app-red'],
      },
      loading: {
        true: ['disabled:opacity-100'],
        false: ['disabled:opacity-50'],
      },
      outline: {
        true: ['!bg-transparent'],
      },
    },
    compoundVariants: [
      {
        intent: 'primary',
        outline: true,
        className: [
          'border-app-primary !text-app-primary hover:!bg-app-primary hover:!text-app-primary-foreground',
        ],
      },
      {
        intent: 'secondary',
        outline: true,
        className: [
          'border-app-secondary !text-app-secondary hover:!bg-app-secondary hover:!text-app-secondary-foreground',
        ],
      },
      {
        intent: 'tertiary',
        outline: true,
        className: ['border-app-accent !text-app-accent'],
      },
      {
        intent: 'danger',
        outline: true,
        className: [
          'border-app-red !text-app-red hover:!bg-app-red hover:!text-white',
        ],
      },
    ],

    defaultVariants: {
      intent: 'primary',
      size: 'md',
    },
  }
);

interface ButtonBodyProps {
  children: React.ReactNode | string;
  icon?: ComponentType<{ className?: string }>;
  size?: 'sm' | 'md' | 'lg';
  loading?: boolean;
  /**
   * Label displayed after the loading is completed
   */
  actionCompleteLabel?: string;
  /**
   * How long the actionComplete label should be displayed.
   * Defaults to 2000ms.
   * Use 0 to disable.
   */
  actionCompleteLabelDuration?: number;
}

const ButtonBody = (props: ButtonBodyProps) => {
  const {
    children,
    icon: Icon,
    size = 'md',
    loading = false,
    actionCompleteLabel,
    actionCompleteLabelDuration = 3000,
  } = props;

  const [previousLoadingState, setPreviousLoadingState] = useState(loading);

  const [showActionCompleteLabel, setShowActionCompleteLabel] = useState(false);

  const hasActionCompleteLabel = !!actionCompleteLabel;

  const timeoutRef = useRef<number>();

  useEffect(() => {
    if (previousLoadingState === loading) {
      return;
    }

    // Loading has just finished and action complete label is set
    if (
      hasActionCompleteLabel &&
      previousLoadingState === true &&
      loading === false
    ) {
      // show the action complete label
      setShowActionCompleteLabel(true);

      // hide it after the duration
      timeoutRef.current = window.setTimeout(() => {
        setShowActionCompleteLabel(false);
      }, actionCompleteLabelDuration);
    }

    // Loading has just started and action complete label is set
    if (
      hasActionCompleteLabel &&
      previousLoadingState === false &&
      loading === true
    ) {
      setShowActionCompleteLabel(false);
      window.clearTimeout(timeoutRef.current);
    }

    // update the previous loading state
    setPreviousLoadingState(loading);
  }, [
    actionCompleteLabelDuration,
    previousLoadingState,
    hasActionCompleteLabel,
    loading,
  ]);

  useEffect(() => {
    return () => {
      window.clearTimeout(timeoutRef.current);
    };
  }, []);

  return (
    <>
      {showActionCompleteLabel && (
        <span
          className="absolute inset-0 flex items-center justify-center"
          aria-hidden="true"
        >
          {actionCompleteLabel}
        </span>
      )}

      {loading && (
        <span
          className="absolute left-1/2 top-1/2 aspect-square h-1/2 -translate-x-1/2 -translate-y-1/2 text-current"
          aria-hidden="true"
        >
          <InlineSpinner className="size-full text-current" />
        </span>
      )}

      <span
        className={clsx('inline-flex items-center justify-center gap-2', {
          invisible: loading || showActionCompleteLabel,
        })}
      >
        {Icon ? (
          <Icon
            className={clsx(' shrink-0', {
              'h-4 w-4': size === 'md',
              'h-3 w-3 ': size === 'sm',
            })}
          />
        ) : null}{' '}
        {children}
      </span>
    </>
  );
};

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> &
  ButtonBodyProps & {
    intent?: 'primary' | 'secondary' | 'tertiary' | 'danger';
    to?: string;
    target?: string;
    outline?: boolean;
    as?: 'button' | 'div';
  };
export const Button = forwardRef<HTMLElement, Props>(
  function Button(props, ref) {
    const {
      className,
      intent,
      size = 'md',
      icon,
      outline,
      to,
      children,
      loading = false,
      actionCompleteLabel,
      actionCompleteLabelDuration,
      disabled,

      ...rest
    } = props;

    const buttonClassName = clsx(
      buttonClasses({ intent, size, outline, loading }),
      className
    );

    if (typeof to === 'string') {
      return (
        <Link
          to={to}
          className={buttonClassName}
          ref={ref as React.Ref<HTMLAnchorElement>}
          rel={rest.rel}
          target={rest.target}
          onClick={
            props.onClick as MouseEventHandler<HTMLAnchorElement> | undefined
          }
        >
          <ButtonBody icon={icon} size={size} loading={loading}>
            {children}
          </ButtonBody>
        </Link>
      );
    }

    if (props.as === 'div') {
      return (
        <div className={buttonClassName}>
          <ButtonBody
            icon={icon}
            size={size}
            loading={loading}
            actionCompleteLabel={actionCompleteLabel}
            actionCompleteLabelDuration={actionCompleteLabelDuration}
          >
            {children}
          </ButtonBody>
        </div>
      );
    }

    return (
      <button
        className={buttonClassName}
        disabled={disabled || loading}
        ref={ref as React.Ref<HTMLButtonElement>}
        type="button"
        {...rest}
      >
        <ButtonBody
          icon={icon}
          size={size}
          loading={loading}
          actionCompleteLabel={actionCompleteLabel}
          actionCompleteLabelDuration={actionCompleteLabelDuration}
        >
          {children}
        </ButtonBody>
      </button>
    );
  }
);
