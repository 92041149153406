import { PageFooter } from '@/components/layout/PageFooter';
import { PageHeader } from '@/components/layout/PageHeader';
import { currentUserQuery } from '@/queries/currentUserQuery';
import { queryClient } from '@/queries/queryClient';
import { useEffect, useRef } from 'react';
import {
  type LoaderFunctionArgs,
  Outlet,
  ScrollRestoration,
  useNavigation,
} from 'react-router-dom';
import LoadingBar, { type LoadingBarRef } from 'react-top-loading-bar';

import { PartnerPageHeader } from '@/components/layout/PartnerPageHeader';
import { useCurrentPartner } from '@/hooks/useCurrentPartner';
import { currentPartnerQuery } from '@/queries/currentPartnerQuery';
import { useChatVisibility } from '../hooks/useChatVisibility';
import { ThemeStyles } from './ThemeStyles';

export async function rootLoader({ params, request }: LoaderFunctionArgs) {
  const { resultId } = params;

  const [currentUser, currentPartner] = await Promise.all([
    queryClient.ensureQueryData(currentUserQuery()),
    queryClient.ensureQueryData(currentPartnerQuery(request.url)),
  ]);

  return {
    currentUser,
    currentPartner,
    resultId,
  };
}

export function Root() {
  const navigation = useNavigation();
  const isLoading = navigation.state !== 'idle';

  const currentPartner = useCurrentPartner();

  useEffect(() => {
    document.body.classList.add(currentPartner ? 'bg-body' : 'bg-app-primary');

    return () => {
      document.body.classList.remove(
        currentPartner ? 'bg-body' : 'bg-app-primary'
      );
    };
  }, [currentPartner]);

  useChatVisibility(true);

  const ref = useRef<LoadingBarRef>(null as never);

  useEffect(() => {
    const current = ref.current;
    if (isLoading) {
      current?.continuousStart();
    } else {
      current?.complete();
    }

    return () => {
      current?.complete();
    };
  }, [isLoading]);

  return (
    <>
      <ThemeStyles />
      <div className="bg-app-primary font-sans text-xl lg:text-2xl">
        {currentPartner ? <PartnerPageHeader /> : <PageHeader />}

        <main className="bg-app-body py-10 xl:py-20">
          <div className="container relative">
            <Outlet />

            <ScrollRestoration />
          </div>
        </main>

        {!currentPartner && <PageFooter />}
      </div>

      <LoadingBar ref={ref} height={5} color="hsl(var(--color-secondary))" />
    </>
  );
}
