import { MarketingSiteLink } from '../MarketingSiteLink';
import logo from '../../assets/onesource-logo.svg';
export function PageFooter() {
  return (
    <>
      <div className="bg-gray-100">
        <div className="container">
          <nav
            aria-label="Secondary Navigation"
            className="grid gap-10 py-10 sm:grid-cols-2 lg:grid-cols-4"
          >
            <div>
              <h4 className="mb-4 font-heading font-bold text-app-secondary lg:text-xl">
                Services
              </h4>
              <ul className="space-y-2 text-sm font-semibold lg:space-y-4">
                <li>
                  <MarketingSiteLink
                    href="/services#television"
                    className="text-app-primary underline hover:no-underline"
                  >
                    Television Services
                  </MarketingSiteLink>
                </li>
                <li>
                  <MarketingSiteLink
                    href="/services#internet"
                    className="text-app-primary underline hover:no-underline"
                  >
                    Internet Services
                  </MarketingSiteLink>
                </li>
                <li>
                  <MarketingSiteLink
                    href="/services#smarthome"
                    className="text-app-primary underline hover:no-underline"
                  >
                    Smart Home Systems
                  </MarketingSiteLink>
                </li>
                <li>
                  <MarketingSiteLink
                    href="/services#phone"
                    className="text-app-primary underline hover:no-underline"
                  >
                    Cell Phone / Home Phone Service
                  </MarketingSiteLink>
                </li>
                <li>
                  <MarketingSiteLink
                    href="/services#electric"
                    className="text-app-primary underline hover:no-underline"
                  >
                    Electric Service
                  </MarketingSiteLink>
                </li>
                <li>
                  <MarketingSiteLink
                    href="/services#utilities"
                    className="text-app-primary underline hover:no-underline"
                  >
                    Utility Services
                  </MarketingSiteLink>
                </li>
              </ul>
            </div>
            <div>
              <h4 className="mb-4 font-heading font-bold text-app-secondary lg:text-xl">
                For Homeowners
              </h4>
              <ul className="space-y-2 text-sm font-semibold lg:space-y-4">
                <li>
                  <MarketingSiteLink
                    href="/homeowners#whowehelp"
                    className="text-app-primary underline hover:no-underline"
                  >
                    Who We Help
                  </MarketingSiteLink>
                </li>
                <li>
                  <MarketingSiteLink
                    href="/homeowners#howitworks"
                    className="text-app-primary underline hover:no-underline"
                  >
                    How it Works
                  </MarketingSiteLink>
                </li>
                <li>
                  <MarketingSiteLink
                    href="/homeowners#services"
                    className="text-app-primary underline hover:no-underline"
                  >
                    Services Offered
                  </MarketingSiteLink>
                </li>
                <li>
                  <MarketingSiteLink
                    href="/homeowners#app-success"
                    className="text-app-primary underline hover:no-underline"
                  >
                    Homeowner Testimonials
                  </MarketingSiteLink>
                </li>
                <li>
                  <MarketingSiteLink
                    href="/homeowners#faqs"
                    className="text-app-primary underline hover:no-underline"
                  >
                    FAQs
                  </MarketingSiteLink>
                </li>
              </ul>
            </div>
            <div>
              <h4 className="mb-4 font-heading font-bold text-app-secondary lg:text-xl">
                For Partners
              </h4>
              <ul className="space-y-2 text-sm font-semibold lg:space-y-4">
                <li>
                  <MarketingSiteLink
                    href="/partners#partner-form"
                    className="text-app-primary underline hover:no-underline"
                  >
                    Become a Partner
                  </MarketingSiteLink>
                </li>
                <li>
                  <MarketingSiteLink
                    href="/partners#whowehelp"
                    className="text-app-primary underline hover:no-underline"
                  >
                    Who We Help
                  </MarketingSiteLink>
                </li>
                <li>
                  <MarketingSiteLink
                    href="/partners#partner-video"
                    className="text-app-primary underline hover:no-underline"
                  >
                    Partner Video
                  </MarketingSiteLink>
                </li>
                <li>
                  <MarketingSiteLink
                    href="/partners#testimonials"
                    className="text-app-primary underline hover:no-underline"
                  >
                    Partner Testimonials
                  </MarketingSiteLink>
                </li>
                <li>
                  <MarketingSiteLink
                    href="/partners#advantage"
                    className="text-app-primary underline hover:no-underline"
                  >
                    Partner Program Advantage
                  </MarketingSiteLink>
                </li>
                <li>
                  <MarketingSiteLink
                    href="/partners#faqs"
                    className="text-app-primary underline hover:no-underline"
                  >
                    FAQs
                  </MarketingSiteLink>
                </li>
              </ul>
            </div>
            <div>
              <h4 className="mb-4 font-heading font-bold text-app-secondary lg:text-xl">
                Helpful Links
              </h4>
              <ul className="space-y-2 text-sm font-semibold lg:space-y-4">
                <li>
                  <MarketingSiteLink
                    href="/contact-us"
                    className="text-app-primary underline hover:no-underline"
                  >
                    Contact Us
                  </MarketingSiteLink>
                </li>
                <li>
                  <MarketingSiteLink
                    href="/about-us"
                    className="text-app-primary underline hover:no-underline"
                  >
                    About Us
                  </MarketingSiteLink>
                </li>
                <li>
                  <MarketingSiteLink
                    href="/privacy-notice"
                    className="text-app-primary underline hover:no-underline"
                  >
                    Privacy Notice
                  </MarketingSiteLink>
                </li>
                <li>
                  <MarketingSiteLink
                    href="/terms-of-use"
                    className="text-app-primary underline hover:no-underline"
                  >
                    Terms/Conditions
                  </MarketingSiteLink>
                </li>
                <li>
                  <MarketingSiteLink
                    href="/careers"
                    className="text-app-primary underline hover:no-underline"
                  >
                    Career Opportunities
                  </MarketingSiteLink>
                </li>
                <li>
                  <MarketingSiteLink
                    href="/client-referral"
                    className="text-app-primary underline hover:no-underline"
                  >
                    Client Referral
                  </MarketingSiteLink>
                </li>
                <li>
                  <a
                    href="https://portal.osconnects.com/"
                    className="text-app-primary underline hover:no-underline"
                  >
                    Partner Login
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
      <footer className="bg-app-primary py-10">
        <div className="container">
          <div className="flex flex-col lg:mb-10 lg:flex-row lg:justify-between">
            <div className="order-2 lg:order-1">
              <div className="mb-8">
                <a
                  href="mailto:hello@osconnects.com"
                  className="text-base font-bold text-app-primary-foreground underline hover:no-underline"
                >
                  hello@osconnects.com
                </a>
              </div>
              <div className="text-app-primary-foreground">
                <h3 className="sr-only">Our Social Media</h3>
                <ul className="flex gap-4">
                  <li>
                    <a
                      href="https://www.facebook.com/OneSourceSolutionsCorp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <svg height="40" width="35" viewBox="0 0 448 512">
                        <title>Follow us On Facebook</title>
                        <path
                          d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"
                          fill="currentColor"
                        />
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/onesource-solutions-home-connections/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="40"
                        width="35"
                        viewBox="0 0 448 512"
                        fill="currentColor"
                      >
                        <title>Follow us On LinkedIn</title>
                        <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" />
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="order-1 mb-8 lg:order-2">
              <MarketingSiteLink href="/">
                <img
                  src={logo}
                  alt="OneSource Home Connections"
                  height="30"
                  width="128"
                  className="lg:h-[60px] lg:w-[255px]"
                />
              </MarketingSiteLink>
            </div>
          </div>
          <hr className="my-10 border-app-primary-foreground" />
          <div className="space-y-4 text-xs text-app-primary-foreground">
            <p>
              &copy; {new Date().getFullYear()} OneSource. All rights reserved.
            </p>
            <p>
              <MarketingSiteLink
                href="/terms-of-use"
                className="text-app-primary-foreground underline hover:no-underline"
              >
                Terms of Use
              </MarketingSiteLink>
            </p>
            <p>
              <MarketingSiteLink
                href="/privacy-notice"
                className="text-app-primary-foreground underline hover:no-underline"
              >
                Privacy Policy
              </MarketingSiteLink>
            </p>
          </div>
        </div>
      </footer>
    </>
  );
}
