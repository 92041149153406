export const MarketingSiteLink = (
  props: React.AnchorHTMLAttributes<HTMLAnchorElement>
) => {
  const { href, children, ...rest } = props;
  return (
    <a href={`${import.meta.env.VITE_MARKETING_SITE_URL}${href}`} {...rest}>
      {children}
    </a>
  );
};
