import { useCallback } from 'react';

export function useChatTriggerCallback() {
  return useCallback(() => {
    const trigger = document.querySelector<HTMLButtonElement>('#chat_btn');
    if (!trigger) {
      return;
    }

    trigger.click();
  }, []);
}
