import { IApiResponse } from '@/types';
import axios from 'axios';
import { ICurrentUserData } from '@/types';

export function getCurrentUserAccount(token?: string) {
  const config = token
    ? {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    : {};
  return axios.get<IApiResponse<ICurrentUserData>>('user/account', config);
}
