import logo from '@/assets/onesource-logo.svg';
import logoDark from '@/assets/onesource-logo-dark.svg';
import { useCurrentPartner } from '@/hooks/useCurrentPartner';
import clsx from 'clsx';

interface Props {
  className?: string;
  variant?: 'light' | 'dark';
}

export const PageLogo = (props: Props) => {
  const { className, variant = 'dark' } = props;
  const currentPartner = useCurrentPartner();

  if (currentPartner?.LogoFile) {
    return (
      <img
        src={currentPartner.LogoFile}
        alt={currentPartner.name}
        width="170"
        height="40"
        className={clsx('h-[40px] w-[170px] object-contain', className)}
      />
    );
  }
  if (!currentPartner) {
    return (
      <img
        src={variant === 'dark' ? logoDark : logo}
        alt="OneSource Home Connections"
        width="170"
        height="40"
        className={className}
      />
    );
  }
  return null;
};
