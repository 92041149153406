import { useEffect } from 'react';

export function useChatVisibility(enable: boolean) {
  useEffect(() => {
    document.querySelector('#chat_box')?.classList.toggle('hidden', !enable);

    return () => {
      document.querySelector('#chat_box')?.classList.toggle('hidden', !enable);
    };
  }, [enable]);
}
