import { crmWSApiRequest } from '@/api/external/crmWSApiRequest';
import { getPartnerBySlug } from '@/api/partners/getPartnerBySlug';
import { IPartner } from '@/types';

type IPartnerCRMData = Pick<
  IPartner,
  | 'AllowWhiteLabel'
  | 'LogoFile'
  | 'PrimaryColor'
  | 'SecondaryColor'
  | 'TertiaryColor'
  | 'PrimaryTextColor'
  | 'SecondaryTextColor'
> & { ProfileID: string };

const APPLIED_DOMAIN = import.meta.env.VITE_APPLIED_DOMAIN || '';

function extractPartnerName(url: string) {
  if (import.meta.env.VITE_DEV_PARTNER) {
    return import.meta.env.VITE_DEV_PARTNER as string;
  }

  if (!APPLIED_DOMAIN) return '';

  const hostname = new URL(url).hostname;

  if (hostname.endsWith(APPLIED_DOMAIN)) {
    const index = hostname.lastIndexOf(APPLIED_DOMAIN);

    const subdomain = hostname.substring(0, index - 1); // -1 to remove the trailing dot

    const parts = subdomain.split('.');
    return parts.length > 0 ? parts[parts.length - 1] : '';
  }

  return '';
}

export function currentPartnerQuery(url: string) {
  const slug = extractPartnerName(url);

  return {
    queryKey: ['current-partner', slug],
    queryFn: async () => {
      if (!slug) {
        return null;
      }

      const {
        data: { data: partnerData },
      } = await getPartnerBySlug(slug);
      if (!partnerData) {
        return null;
      }

      const response = await crmWSApiRequest<{
        Results: { Partners: IPartnerCRMData[] };
      }>('partner-list', {
        ProfileID: partnerData.profile_id,
      });

      const partnerCRMData = response.Results.Partners.find(
        (data) =>
          data.ProfileID.toString() === partnerData.profile_id.toString()
      );

      if (!partnerCRMData) {
        return null;
      }

      return { ...partnerData, ...partnerCRMData } as IPartner;
    },
    staleTime: Number.POSITIVE_INFINITY,
  };
}
