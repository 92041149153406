import { useEffect, useState } from 'react';
import { useChatVisibility } from '../hooks/useChatVisibility';

export const AppLoader = () => {
  const [message, setMessage] = useState('Loading...');

  useChatVisibility(false);
  useEffect(() => {
    const timeout = window.setTimeout(() => {
      setMessage('Still loading...');
    }, 4000);

    return () => {
      window.clearTimeout(timeout);
    };
  }, []);
  return (
    <div className="flex min-h-screen items-center justify-center bg-app-primary">
      <div className=" text-app-primary-foreground">{message}</div>
    </div>
  );
};
